function Editor(areaID, inputImages){
    var images = inputImages;
    var textarea = $(areaID)[0];
    var that = this;
    var focused=false;

//create menu
    var createMenu = function(){
        var menu = document.createElement("div");

        var head = document.createElement("div");
        var headText = document.createTextNode("Untertitel 1");
        head.appendChild(headText);
        head.style.fontWeight = "bold";
        head.addEventListener("click", function(){writeTag("head");});
        menu.appendChild(head);

        var subhead = document.createElement("div");
        var subheadText = document.createTextNode("Untertitel 2");
        subhead.appendChild(subheadText);
        subhead.addEventListener("click", function(){writeTag("subhead");});
        menu.appendChild(subhead);

        var bold = document.createElement("div");
        var boldText = document.createTextNode("b");
        bold.appendChild(boldText);
        bold.style.fontWeight = "bold";
        bold.addEventListener("click", function(){writeTag("b");});
        menu.appendChild(bold);

        var italic = document.createElement("div");
        var italicText = document.createTextNode("i");
        italic.appendChild(italicText);
        italic.style.fontStyle = "italic";
        italic.addEventListener("click", function(){writeTag("i");});
        menu.appendChild(italic);

        var underline = document.createElement("div");
        var underlineText = document.createTextNode("u");
        underline.appendChild(underlineText);
        underline.style.textDecoration = "underline";
        underline.addEventListener("click", function(){writeTag("u");});
        menu.appendChild(underline);

        var quote = document.createElement("div");
        var quoteText = document.createTextNode("\"\"");
        quote.appendChild(quoteText);
        quote.addEventListener("click", function(){writeTag("quote");});
        menu.appendChild(quote);

        var url = document.createElement("div");
        var urlText = document.createTextNode("url");
        url.appendChild(urlText);
        url.addEventListener("click", function(){writeAdvancedTag("url","Pfad angeben:");});
        menu.appendChild(url);

        var img = document.createElement("div");
        var imgImg = document.createElement("img");
        imgImg.setAttribute("src","/media/img.jpg");
        imgImg.style.width = "20px";
        imgImg.style.margin = "0";
        img.appendChild(imgImg);
        img.addEventListener("click", function(){writeImgTag("img");});
        menu.appendChild(img);

        for(var i=0;i<menu.children.length;i++)
        {
            menu.children[i].style.display = "inline-block";
            menu.children[i].style.float = "left";
            menu.children[i].style.marginRight="2px";
            menu.children[i].style.marginBottom="10px";
            menu.children[i].style.backgroundColor = "#DDDDDD";
            menu.children[i].style.background = "linear-gradient(#EEE, #BBB)";
            menu.children[i].style.border = "solid 1px black";
            menu.children[i].style.minWidth = "30px";
            menu.children[i].style.height = "30px";
            menu.children[i].style.padding = "4px";

            menu.children[i].style.textAlign = "center";
            menu.children[i].style.cursor = "pointer";
        }
        return menu;
    };

    var menu = createMenu();

    textarea.addEventListener("focus",function(){
        textarea.parentNode.insertBefore(menu,textarea);
        focused=true;
    });
    textarea.addEventListener("blur",function(){
        focused=false;
        setTimeout(function(){if(!focused)textarea.parentNode.removeChild(menu);},500);
    });






    var writeTag = function (tag)
    {
        var start = textarea.selectionStart;
        var end = textarea.selectionEnd;
        var selection = textarea.value.substring(start, end);

        //check if in tag
        if(textarea.value.substring(start-tag.length-2,start)=='['+tag+']'&&textarea.value.substring(end, end+tag.length+3)=='[/'+tag+']')
        {
            var starttext = textarea.value.substring(0,start-2-tag.length);
            var endtext = textarea.value.substring(end+tag.length+3);
            var newtxt = starttext + selection + endtext;
            textarea.value = newtxt;
            textarea.focus();
            textarea.selectionStart = start-tag.length-2;
            textarea.selectionEnd = end-tag.length-2;
        }
        else
        {
            var starttext = textarea.value.substring(0,start);
            var endtext = textarea.value.substring(end);
            var edited = '['+tag+']' + selection + '[/'+tag+']';
            var newtxt = starttext + edited + endtext;
            textarea.value = newtxt;
            textarea.focus();
            textarea.selectionStart = start+tag.length+2;
            textarea.selectionEnd = end+tag.length+2;
        }
        focused=true;
    }

    var writeAdvancedTag = function (tag, addendum)
    {
        var start = textarea.selectionStart;
        var end = textarea.selectionEnd;
        var selection = textarea.value.substring(start, end);
        //check if in tag
        var starttext = textarea.value.substring(0,start);
        var endtext = textarea.value.substring(end);
        var tagAdd = prompt(addendum, "http://");
        var edited = '['+tag+' '+tagAdd+']' + selection + '[/'+tag+']';
        var newtxt = starttext + edited + endtext;
        textarea.value = newtxt;
        textarea.focus();
        textarea.selectionStart = start+tag.length+tagAdd.length+3;
        textarea.selectionEnd = end+tag.length+tagAdd.length+3;
        focused=true;
    }

    var writeImgTag = function ()
    {
        var start = textarea.selectionStart;
        var end = textarea.selectionEnd;
        var selection = textarea.value.substring(start, end);

        //check if in tag
        var starttext = textarea.value.substring(0,start);
        var endtext = textarea.value.substring(end);

        //picker window

        var picker = document.createElement("div");
        picker.style.position = "absolute";


        var src = prompt("Namen aus der Mediathek angeben:", "");
        var size = prompt("Größe angeben (in Pixeln, leer lassen für Originalgröße)","");
        size = size==""?"":" "+size+"px";
        var inText = confirm("Vom Text umflossen?")?" imText":"";
        var edited = '['+"img"+' '+src+size+inText+']' + selection;
        var newtxt = starttext + edited + endtext;
        textarea.value = newtxt;
        textarea.focus();
        textarea.selectionStart = start+3+src.length+size.length+inText.length+3;
        textarea.selectionEnd = end+3+src.length+size.length+inText.length+3;
        focused=true;
    }
};